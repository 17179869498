export const appConfig =
{
  'apiKey': 'AIzaSyC86lJjkDmDk3jGHZi4i2uKQpgb-LO6Y1w',
  'appId': '1:94806958885:web:6e2b06a2915ed8875bf0cc',
  'authDomain': 'schooldog-i-brantley-ga.firebaseapp.com',
  'measurementId': 'G-Z15ZP2PLW4',
  'messagingSenderId': '94806958885',
  'project': 'schooldog-i-brantley-ga',
  'projectId': 'schooldog-i-brantley-ga',
  'showGaBranding': true,
  'storageBucket': 'schooldog-i-brantley-ga.appspot.com',
}
